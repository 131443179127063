<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <!--        <a-button v-action:add type="primary" @click="handleAdd">增加运单</a-button>-->
        <!--        <SearchForm ref="SearchForm" :addressList="addressList" @handleSubmit="handleSeachSubmit"/>-->
      </div>
      <s-table
          ref="table"
          rowKey="instructions_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{x:true}"
      >
        <div slot="file" slot-scope="text, item">
          <a @click="priview(text)">预览文件</a>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <!--          <a v-action:edit @click="handleEdit(item)">编辑</a>-->
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/msds/instructions'
import {STable} from "@/components";

export default {
  name: "FixListModel",
  components: {STable},
  data() {
    return {
      // 标题
      title: '说明书信息',
      // 查询参数
      queryParam: {
        msds_info_id: ''
      },
      // modal(对话框)是否可见
      visible: false,

      loading: false,

      isLoading: false,

      // 客户id
      msds_info_id: "",

      columns: [
        // {
        //   title: '确认ID',
        //   dataIndex: 'fix_id',
        //   align: 'center',
        //   width: 60,
        // },
        {
          title: '提交用户',
          dataIndex: 'personnel.personnel_name',
          align: 'center',
        },
        {
          title: '说明书文件',
          dataIndex: 'file',
          align: 'center',
          scopedSlots: {customRender: 'file'}
        },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   align: 'center',
        //   scopedSlots: {customRender: 'action'}
        // },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },

  methods: {
    /**
     * 显示弹窗
     */
    open({msds_info_id}) {
      this.queryParam.msds_info_id = msds_info_id
      this.msds_info_id = msds_info_id
      this.visible = true
    },

    priview(item) {
      window.open('https://www.pfile.com.cn/api/profile/onlinePreview?url=' + item.external_url)
    },

    /**
     * 关闭弹窗
     */
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      Api.deleted({apiId: item['instructions_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },


    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
