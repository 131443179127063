<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="msds_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
    >
      <div slot="cover" slot-scope="text, item">
        <img :src="text" style="width: 60px;height: 60px"/>
      </div>
      <div slot="message_content" slot-scope="text, item">
        {{ text.length > 20 ? text.slice(0, 20) + '...' : text }}
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <a v-action:details @click="handleDetails(item)">查看详情</a>
        <!--        <a v-action:edit @click="handleEdit(item)">编辑</a>-->
        <!--        <a v-action:delete @click="handleDelete(item)">删除</a>-->
      </div>
    </s-table>
    <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
    <DetailsModel ref="DetailsModel" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/msds'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm'
import SearchForm from './modules/SearchForm'
import DetailsModel from './modules/DetailsModel'

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm,
    DetailsModel
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          align: 'center',
          width: 65,
          title: '标识',
          dataIndex: 'cover',
          scopedSlots: {customRender: 'cover'},
        },
        {
          align: 'center',
          width: 120,
          title: 'CAS号',
          dataIndex: 'cas',
        },
        {
          align: 'center',
          width: 120,
          title: 'UN号',
          dataIndex: 'unCode'
        },
        {
          width: 100,
          title: '化学品名称',
          align: 'center',
          dataIndex: 'name',
          ellipsis: true,
        },
        {
          align: 'center',
          width: 120,
          title: '危险性类别',
          dataIndex: 'cnCategory'
        },
        {
          width: 200,
          title: '英文名称',
          dataIndex: 'enAliasName',
          ellipsis: true,
        },
        {
          width: 200,
          title: '中文别名',
          dataIndex: 'cnAliasName',
          ellipsis: true,
        },

        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {
    handleDetails(item) {
      this.$refs.DetailsModel.open(item)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({messageId: item['message_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
